import React, { useState } from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github_dark";
import "ace-builds/webpack-resolver";

function JSONInput(props) {
  const [editorValue, setEditorValue] = useState(props.value || "");

  function handleEditorChange(newValue) {
    setEditorValue(newValue);
    if (props.onChange) {
      props.onChange(newValue);
    }
  }

  return (
    <AceEditor
      mode="json"
      theme="github_dark"
      value={editorValue}
      onChange={handleEditorChange}
      setOptions={{
        showLineNumbers: false,
      }}
    />
  );
}

export default JSONInput;
