import React, { useState } from "react";
import "./ForgottenPassword.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const ForgottenPassword = () => {
  let navigate = useNavigate();
  const [verificationCode, setVerificationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [reEnterNewPassword, setReEnterNewPassword] = useState("");

  const handleVerificationCodeChange = (event) => {
    setVerificationCode(event.target.value);
  };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleReEnterNewPasswordChange = (event) => {
    setReEnterNewPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
    if (newPassword !== reEnterNewPassword) {
      toast.warning("Passwords do not match.", {
        position: "top-left",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        progressStyle: { background: "#1DB954" },
        theme: "colored",
        style: { background: "#001c30", fontFamily: "Open Sans" },
      });
      return;
    }
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: localStorage.getItem("tempEmail"),
      code: verificationCode,
      newPassword: newPassword,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api.jsonplacehold.me/authentication/resetPassword",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        let resultJSON = JSON.parse(result);
        if (resultJSON.key === "SUCCESS") {
          localStorage.removeItem("tempEmail");
          navigate("/");
        } else {
          toast.warning(resultJSON.message, {
            position: "top-left",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
            progressStyle: { background: "#1DB954" },
            theme: "colored",
            style: { background: "#001c30", fontFamily: "Open Sans" },
          });
        }
      })
      .catch((error) => console.log("error", error));
    toast.warning("An error occurred. Please try again.", {
      position: "top-left",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
      progressStyle: { background: "#1DB954" },
      theme: "colored",
      style: { background: "#001c30", fontFamily: "Open Sans" },
    });
  };

  return (
    <div className={`forgotten-password-container`}>
      <form className="forgotten-password-form" onSubmit={handleSubmit}>
        <h1 className="forgotten-password-heading">Forgotten Password</h1>

        <div className="form-group">
          <label htmlFor="verification-code">Verification Code</label>
          <div className="input-field-wrapper">
            <input
              type="text"
              inputMode="numeric"
              id="verification-code"
              className="input-field"
              placeholder="Enter verification code"
              value={verificationCode}
              onChange={handleVerificationCodeChange}
            />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="new-password">New Password</label>
          <input
            type="password"
            id="new-password"
            className="input-field"
            placeholder="Enter new password"
            value={newPassword}
            onChange={handleNewPasswordChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="re-enter-new-password">Re-enter New Password</label>
          <input
            type="password"
            id="re-enter-new-password"
            className="input-field"
            placeholder="Re-enter new password"
            value={reEnterNewPassword}
            onChange={handleReEnterNewPasswordChange}
          />
        </div>
        <button type="submit" className="forgotten-password-button">
          Submit
        </button>
      </form>
      <ToastContainer
        position="top-left"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default ForgottenPassword;
