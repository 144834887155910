import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import "./Project.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TailSpin } from "react-loader-spinner";
import JSONInput from "./components/JSONInput";
import { useNavigate } from "react-router-dom";

const Project = () => {
  let navigate = useNavigate();
  const [project, setProject] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [openCreateEndpointDialog, setOpenCreateEndpointDialog] =
    useState(false);

  const [openDeleteEndpointDialog, setOpenDeleteEndpointDialog] =
    useState(false);
  const [endpointPath, setEndpointPath] = useState("");
  const [endpointMethod, setEndpointMethod] = useState("GET");
  const [editorValue, setEditorValue] = useState("");
  const [selectedEndpoint, setSelectedEndpoint] = useState(null);
  const [openEditEndpointDialog, setOpenEditEndpointDialog] = useState(false);
  const [editEditorValue, setEditEditorValue] = useState("");
  const [editMethod, setEditMethod] = useState("");
  const [editPath, setEditPath] = useState("");

  useEffect(() => {
    fetchProject();
  }, []);
  // Get the current URL
  const currentUrl = window.location.href;

  // Split the URL by '/' to get the parts of the URL
  const urlParts = currentUrl.split("/");

  // Find the position of 'project' in the URL
  const projectIndex = urlParts.indexOf("project");

  // Extract the project ID from the URL based on its position
  const projectId = projectIndex !== -1 ? urlParts[projectIndex + 1] : null;

  const fetchProject = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://api.jsonplacehold.me/project/" + projectId, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let resultJSON = JSON.parse(result);
        console.log(resultJSON);
        if (resultJSON.key === "SUCCESS") {
          setProject(resultJSON.body);
          setIsLoading(false);
        } else if (resultJSON.key === "UNAUTHORIZED") {
          localStorage.clear();
          toast.warning("Your session is expired please re-login", {
            position: "top-left",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
            progressStyle: { background: "#1DB954" },
            theme: "colored",
            style: { background: "#001c30", fontFamily: "Open Sans" },
          });
          //window.location.href = "/";
        } else {
          toast.warning(resultJSON.message, {
            position: "top-left",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
            progressStyle: { background: "#1DB954" },
            theme: "colored",
            style: { background: "#001c30", fontFamily: "Open Sans" },
          });
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleOpenCreateEndpointDialog = () => {
    setOpenCreateEndpointDialog(true);
  };

  const handleCloseCreateEndpointDialog = () => {
    setOpenCreateEndpointDialog(false);
  };

  const handleEndpointPathChange = (e) => {
    setEndpointPath(e.target.value);
  };

  const handleEndpointMethodChange = (e) => {
    setEndpointMethod(e.target.value);
  };

  const handleEditorChange = (newValue) => {
    setEditorValue(newValue); // Update the local state in Project component
  };

  const handleCloseDeleteEndpointDialog = () => {
    setOpenDeleteEndpointDialog(false);
  };
  const handleConfirmDelete = () => {
    handleDeleteEndPoint(selectedEndpoint);
    setOpenDeleteEndpointDialog(false);
    navigate("/project/" + projectId);
  };
  const handleCreateEndpoint = () => {
    let isInvalid = false;

    try {
      JSON.parse(editorValue);
    } catch (e) {
      console.error(e);
      isInvalid = true;
    }

    if (isInvalid) {
      toast.warning("Invalid JSON", {
        position: "top-left",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        progressStyle: { background: "#1DB954" },
        theme: "colored",
        style: { background: "#001c30", fontFamily: "Open Sans" },
      });
      return;
    }

    // Continue with endpoint creation
    const currentUrl = window.location.href;
    const urlParts = currentUrl.split("/");
    const projectIndex = urlParts.indexOf("project");
    const projectId = projectIndex !== -1 ? urlParts[projectIndex + 1] : null;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    var raw = JSON.stringify({
      projectUUID: projectId,
      json: JSON.parse(editorValue),
      method: endpointMethod,
      path: endpointPath,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://api.jsonplacehold.me/project/createEndpoint", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let resultJSON = JSON.parse(result);
        if (resultJSON.key === "UNAUTHORIZED") {
          localStorage.clear();
          toast.warning("Your session is expired please re-login", {
            position: "top-left",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
            progressStyle: { background: "#1DB954" },
            theme: "colored",
            style: { background: "#001c30", fontFamily: "Open Sans" },
          });
          navigate("/");
        } else if (resultJSON.key !== "SUCCESS") {
          toast.warning(resultJSON.message, {
            position: "top-left",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
            progressStyle: { background: "#1DB954" },
            theme: "colored",
            style: { background: "#001c30", fontFamily: "Open Sans" },
          });
        } else {
          handleCloseCreateEndpointDialog();
          navigate("/project/" + projectId);
        }
      })
      .catch((error) => console.error("error", error));
  };

  const handleDeleteEndPoint = (endpoint) => {
    setIsLoading(true);
    console.log(endpoint);
    const currentUrl = window.location.href;

    // Split the URL by '/' to get the parts of the URL
    const urlParts = currentUrl.split("/");

    // Find the position of 'project' in the URL
    const projectIndex = urlParts.indexOf("project");

    // Extract the project ID from the URL based on its position
    const projectId = projectIndex !== -1 ? urlParts[projectIndex + 1] : null;
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    var raw = "";

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api.jsonplacehold.me/project/deleteEndpoint/" +
        projectId +
        "/" +
        endpoint.pathId +
        "",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        let resultJSON = JSON.parse(result);
        if (resultJSON.key === "SUCCESS") {
          setIsLoading(false);
          navigate("/project/" + projectId);
        } else if (resultJSON.key === "UNAUTHORIZED") {
          localStorage.clear();
          toast.warning("Your session is expired please re-login", {
            position: "top-left",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
            progressStyle: { background: "#1DB954" },
            theme: "colored",
            style: { background: "#001c30", fontFamily: "Open Sans" },
          });
          navigate("/");
        } else {
          toast.warning(resultJSON.message, {
            position: "top-left",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
            progressStyle: { background: "#1DB954" },
            theme: "colored",
            style: { background: "#001c30", fontFamily: "Open Sans" },
          });
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleOpenEditEndpointDialog = (endpoint) => {
    console.log(endpoint);
    setEditEditorValue(JSON.stringify(endpoint.json)); // Assuming endpoint.json contains the JSON to be edited
    setEditMethod(endpoint.method); // Assuming endpoint.method contains the method to be edited
    setEditPath(endpoint.path); // Assuming endpoint.path contains the path to be edited
    setSelectedEndpoint(endpoint);
    setOpenEditEndpointDialog(true);
  };

  const handleCloseEditEndpointDialog = () => {
    setOpenEditEndpointDialog(false);
  };

  const handleEditEndpoint = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    const currentUrl = window.location.href;
    const urlParts = currentUrl.split("/");
    const projectIndex = urlParts.indexOf("project");
    const projectId = projectIndex !== -1 ? urlParts[projectIndex + 1] : null;

    var raw = JSON.stringify({
      projectUUID: projectId,
      json: JSON.parse(editEditorValue), // Assuming editEditorValue is a string
      method: editMethod,
      path: editPath,
      endpointId: selectedEndpoint.pathId,
    });

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://api.jsonplacehold.me/project/updateEndpoint", requestOptions)
      .then((response) => response.json()) // Changed to response.json() to parse the JSON response
      .then((resultJSON) => {
        if (resultJSON.key === "UNAUTHORIZED") {
          localStorage.clear();
          toast.warning("Your session is expired please re-login", {
            position: "top-left",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
            progressStyle: { background: "#1DB954" },
            theme: "colored",
            style: { background: "#001c30", fontFamily: "Open Sans" },
          });
          navigate("/");
        } else if (resultJSON.key !== "SUCCESS") {
          toast.warning(resultJSON.message, {
            position: "top-left",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
            progressStyle: { background: "#1DB954" },
            theme: "colored",
            style: { background: "#001c30", fontFamily: "Open Sans" },
          });
        } else {
          handleCloseEditEndpointDialog();
          navigate("/project/" + projectId);
        }
      })
      .catch((error) => console.error("error", error)); // Changed to console.error for better error logging
  };

  if (!isLoading) {
    return (
      <div>
        <Navbar activePage="dashboard" />
        <div className="endpoints-container">
          <h1>Projects</h1>
          <div className="create-endpoint-button-container">
            <button
              onClick={handleOpenCreateEndpointDialog}
              className="create-endpoint-button"
            >
              Create Endpoint
            </button>
          </div>
          <table className="endpoints-table">
            <thead>
              <tr>
                <th>Path</th>
                <th>Method</th>
                <th>Endpoint</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {project.endpoints.map((endpoint) => (
                <tr key={endpoint.pathId}>
                  <td>{endpoint.path}</td>
                  <td>{endpoint.method}</td>
                  <td>
                    {`https://api.jsonplacehold.me/${projectId}/${endpoint.path}`}
                  </td>

                  <td>
                    <Button
                      onClick={() => {
                        window.location.href = endpoint.url;
                      }}
                    >
                      View JSON
                    </Button>
                    <Button
                      onClick={() => {
                        handleOpenEditEndpointDialog(endpoint);
                      }}
                    >
                      Edit JSON
                    </Button>
                    <IconButton
                      color="error"
                      aria-label="Delete"
                      onClick={() => {
                        setSelectedEndpoint(endpoint);
                        setOpenDeleteEndpointDialog(true);
                      }}
                      className="delete-icon-button"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Dialog
          open={openDeleteEndpointDialog}
          PaperProps={{
            style: {
              backgroundColor: "#001C30",
              boxShadow: "none",
              borderRadius: "30px",
              color: "#fff",
            },
          }}
        >
          <DialogTitle>Delete Endpoint</DialogTitle>
          <DialogContent>
            Are you sure you want to delete the endpoint?
          </DialogContent>
          <DialogActions>
            <button
              onClick={() => {
                setOpenDeleteEndpointDialog(false);
              }}
              className="dialogButton"
            >
              Cancel
            </button>
            <button onClick={handleConfirmDelete} className="dialogButton">
              Delete
            </button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openCreateEndpointDialog}
          onClose={handleCloseCreateEndpointDialog}
          PaperProps={{
            style: {
              backgroundColor: "#001C30",
              boxShadow: "none",
              borderRadius: "30px",
              width: "600px",
            },
          }}
        >
          <DialogContent
            style={{ color: "#fff", fontSize: "16px", textAlign: "center" }}
          >
            <label>Path</label>
            <input
              type="text"
              id="endpoint-path"
              className="input-field"
              placeholder="Enter the endpoint path"
              value={endpointPath}
              onChange={handleEndpointPathChange}
            />

            <label>Method</label>
            <select
              id="endpoint-method"
              className="input-field"
              value={endpointMethod}
              onChange={handleEndpointMethodChange}
            >
              <option value="GET">GET</option>
              <option value="POST">POST</option>
              <option value="PUT">PUT</option>
              <option value="PATCH">PATCH</option>
              <option value="DELETE">DELETE</option>
            </select>

            <label>Response Body</label>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <JSONInput
                value={editorValue} // Pass the value as a prop
                onChange={handleEditorChange} // Capture changes in the parent component
              />
            </div>
          </DialogContent>

          <DialogActions
            style={{
              padding: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={handleCreateEndpoint}
              variant="contained"
              autoFocus
              style={{
                padding: "10px 20px",
                borderRadius: "5px",
                backgroundColor: "#1DB954",
                color: "#fff",
                fontSize: "16px",
                fontFamily: "Open Sans",
                cursor: "pointer",
                transition: "background-color 0.3s ease",
              }}
            >
              Create Endpoint
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openEditEndpointDialog}
          onClose={handleCloseEditEndpointDialog}
          PaperProps={{
            style: {
              backgroundColor: "#001C30",
              boxShadow: "none",
              borderRadius: "30px",
              width: "600px",
            },
          }}
        >
          <DialogContent
            style={{ color: "#fff", fontSize: "16px", textAlign: "center" }}
          >
            <label>Path</label>
            <input
              type="text"
              value={editPath}
              onChange={(e) => setEditPath(e.target.value)}
              className="input-field"
            />

            <label>Method</label>
            <select
              value={editMethod}
              onChange={(e) => setEditMethod(e.target.value)}
              className="input-field"
            >
              <option value="GET">GET</option>
              <option value="POST">POST</option>
              <option value="PUT">PUT</option>
              <option value="PATCH">PATCH</option>
              <option value="DELETE">DELETE</option>
            </select>

            <label>Response Body</label>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <JSONInput
                value={editEditorValue}
                onChange={(newValue) => setEditEditorValue(newValue)}
              />
            </div>
          </DialogContent>
          <DialogActions
            style={{
              padding: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={handleEditEndpoint}
              variant="contained"
              autoFocus
              style={{
                padding: "10px 20px",
                borderRadius: "5px",
                backgroundColor: "#1DB954",
                color: "#fff",
                fontSize: "16px",
                fontFamily: "Open Sans",
                cursor: "pointer",
                transition: "background-color 0.3s ease",
              }}
            >
              Edit Endpoint
            </Button>
          </DialogActions>
        </Dialog>

        <ToastContainer
          position="top-left"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </div>
    );
  } else {
    return (
      <div>
        <Navbar activePage="dashboard" />
        <div className="endpoints-container">
          <h1>Projects</h1>
          <div className="create-endpoint-button-container">
            <button onClick={() => {}} className="create-endpoint-button">
              Create Endpoint
            </button>
          </div>
          <table className="endpoints-table">
            <thead>
              <tr>
                <th>Path</th>
                <th>Method</th>
                <th>Actions</th>
              </tr>
            </thead>
          </table>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "50px",
            }}
          >
            <TailSpin
              height="80"
              width="80"
              color="#1DB954"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperClass=""
              visible={true}
            />
          </div>
        </div>
        <ToastContainer
          position="top-left"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </div>
    );
  }
};

export default Project;
