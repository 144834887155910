import React from "react";
import { styled } from "@mui/system";
import { AppBar, Toolbar, Button } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import logo from "./assets/jsonplaceholdme-logo.png";
import { useNavigate } from "react-router-dom";

const ButtonStyled = styled(Button)(({ theme, isActive }) => ({
  color: isActive ? "#FFFFFF" : "#1DB954",
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
}));

const theme = createTheme({
  typography: {
    fontFamily: "Open Sans",
    fontSize: 17,
    fontWeightBold: 600,
    fontWeightRegular: 600,
    fontWeightMedium: 600,
  },
});

const Navbar = ({ activePage }) => {
  let navigate = useNavigate();
  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static" sx={{ backgroundColor: "#001C30" }}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <img
            src={logo}
            alt="Logo"
            style={{
              width: 125,
              height: 110,
              marginTop: "10px",
              marginLeft: "10px",
            }}
          />

          <div>
            <ButtonStyled
              onClick={() => {
                navigate("/dashboard");
              }}
              isActive={activePage === "dashboard"}
            >
              Project Dashboard
            </ButtonStyled>
            <ButtonStyled
              isActive={activePage === "account"}
              onClick={() => {
                navigate("/account");
                activePage = "account";
              }}
            >
              Account
            </ButtonStyled>
            <ButtonStyled
              isActive={activePage === "about"}
              onClick={() => {
                navigate("/about");
              }}
            >
              About
            </ButtonStyled>
            <ButtonStyled
              onClick={() => {
                activePage = "login";
                localStorage.clear();
                navigate("/");
              }}
              isActive={activePage === "signout"}
            >
              Sign out
            </ButtonStyled>
          </div>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
};

export default Navbar;
