import React, { useState, useEffect } from "react";
import "./Verification.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const Verification = () => {
  let navigate = useNavigate();
  const [verificationCode, setVerificationCode] = useState("");
  const [getCodeClicked, setGetCodeClicked] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let countdownInterval = null;

    if (getCodeClicked) {
      setCountdown(59);
      countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 0) {
            setGetCodeClicked(false);
            clearInterval(countdownInterval);
          }
          return prevCountdown > 0 ? prevCountdown - 1 : prevCountdown;
        });
      }, 1000);
    }

    return () => {
      clearInterval(countdownInterval);
    };
  }, [getCodeClicked]);

  const handleVerificationCodeChange = (event) => {
    const input = event.target.value;
    const numericInput = input.replace(/\D/g, ""); // Remove non-numeric characters

    setVerificationCode(numericInput);
  };

  const handleGetCodeClick = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var raw = "";

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    setIsLoading(true);
    fetch(
      "https://api.jsonplacehold.me/authentication/sendCode",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        setIsLoading(false);
        let resultJSON = JSON.parse(result);
        if (resultJSON.key !== "SUCCESS") {
          toast.warning(resultJSON.message, {
            position: "top-left",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
            progressStyle: { background: "#1DB954" },
            theme: "colored",
            style: { background: "#001c30", fontFamily: "Open Sans" },
          });
        }
        if (resultJSON.key === "UNAUTHORIZED") {
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("error", error);
        toast.warning("An error occurred. Please try again.", {
          position: "top-left",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
          progressStyle: { background: "#1DB954" },
          theme: "colored",
          style: { background: "#001c30", fontFamily: "Open Sans" },
        });
      });

    setGetCodeClicked(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setIsLoading(true);

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      code: verificationCode,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://api.jsonplacehold.me/authentication/verify", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let resultJSON = JSON.parse(result);
        console.log(resultJSON);
        if (resultJSON.key !== "SUCCESS") {
          toast.warning(resultJSON.message, {
            position: "top-left",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
            progressStyle: { background: "#1DB954" },
            theme: "colored",
            style: { background: "#001c30", fontFamily: "Open Sans" },
          });
        } else {
          navigate("/");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        toast.warning("An error occurred. Please try again.", {
          position: "top-left",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
          progressStyle: { background: "#1DB954" },
          theme: "colored",
          style: { background: "#001c30", fontFamily: "Open Sans" },
        });
        setIsLoading(false);
      });
  };

  return (
    <div className={`verification-container`}>
      <form className="verification-form" onSubmit={handleSubmit}>
        <h1 className="verification-heading">Verification</h1>
        <div className="form-group">
          <label htmlFor="verification-code">Verification Code</label>
          <div className="input-field-wrapper">
            <input
              type="text"
              inputMode="numeric"
              id="verification-code"
              className="input-field"
              placeholder="Enter verification code"
              value={verificationCode}
              onChange={handleVerificationCodeChange}
            />
            <p
              className={`get-code-text ${getCodeClicked ? "disabled" : ""}`}
              onClick={handleGetCodeClick}
            >
              {getCodeClicked
                ? countdown > 0
                  ? countdown
                  : "Get Code"
                : "Get Code"}
            </p>
          </div>
        </div>
        <button
          type="submit"
          className="verification-button"
          disabled={isLoading}
        >
          {isLoading ? "Loading..." : "Submit"}
        </button>
      </form>
      <ToastContainer
        position="top-left"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default Verification;
