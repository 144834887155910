import React, { useState } from "react";
import "./Login.css";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const Login = () => {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");
  const [showCodeDialog, setShowCodeDialog] = useState(false);
  const [codeEmail, setCodeEmail] = useState("");

  const [isLoading, setIsLoading] = useState(false); // Set loading state to false

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleRegister = (event) => {
    event.preventDefault();
    navigate("/sign-up");
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: email,
      password: password,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://api.jsonplacehold.me/authentication/login", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let resultJSON = JSON.parse(result);
        if (resultJSON.key === "SUCCESS") {
          localStorage.setItem("token", resultJSON.body.token);
          localStorage.setItem(
            "email ",
            JSON.stringify(resultJSON.body.user.email)
          );
          localStorage.setItem(
            "userId",
            JSON.stringify(resultJSON.body.user.id)
          );
          navigate("/dashboard");
        } else {
          if (resultJSON.key === "UNVERIFIED_ACCOUNT") {
            localStorage.setItem("token", resultJSON.body.token);
            localStorage.setItem(
              "email ",
              JSON.stringify(resultJSON.body.user.email)
            );
            localStorage.setItem(
              "userId",
              JSON.stringify(resultJSON.body.user.id)
            );
            setDialogTitle("One more step! Verification is required.");
            setDialogMessage(resultJSON.message);
            setShowDialog(true);
          } else {
            toast.warning(resultJSON.message, {
              position: "top-left",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
              progressStyle: { background: "#1DB954" },
              theme: "colored",
              style: { background: "#001c30", fontFamily: "Open Sans" },
            });
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
        toast.warning("An error occured please try again.", {
          position: "top-left",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
          progressStyle: { background: "#1DB954" },
          theme: "colored",
          style: { background: "#001c30", fontFamily: "Open Sans" },
        });
      });
  };

  const handleForgotPassword = () => {
    setShowCodeDialog(true);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
    navigate("/verify");
  };

  const handleGetCode = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: codeEmail,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    setIsLoading(true); // Set loading state to true

    fetch(
      "https://api.jsonplacehold.me/authentication/resetPasswordMail",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        let resultJSON = JSON.parse(result);
        if (resultJSON.key === "SUCCESS") {
          localStorage.setItem("tempEmail", codeEmail);
          navigate("/ForgottenPassword");
        } else {
          toast.warning(resultJSON.message, {
            position: "top-left",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
            progressStyle: { background: "#1DB954" },
            theme: "colored",
            style: { background: "#001c30", fontFamily: "Open Sans" },
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        toast.warning("An error occurred. Please try again.", {
          position: "top-left",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
          progressStyle: { background: "#1DB954" },
          theme: "colored",
          style: { background: "#001c30", fontFamily: "Open Sans" },
        });
      });
  };

  const handleCodeEmailChange = (event) => {
    setCodeEmail(event.target.value);
  };

  const handleCloseCodeDialog = () => {
    setShowCodeDialog(false);
    setCodeEmail("");
  };

  return (
    <div className={`login-container`}>
      <form className="login-form" onSubmit={handleSubmit}>
        <h1 className="login-heading">Login</h1>

        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            className="input-field"
            placeholder="Enter your email"
            value={email}
            onChange={handleEmailChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            className="input-field"
            placeholder="Enter your password"
            value={password}
            onChange={handlePasswordChange}
          />
        </div>
        <div className="form-group">
          <button type="submit" className="login-button">
            Login
          </button>
        </div>
        <p className="forgot-password" onClick={handleForgotPassword}>
          Forgot your password?
        </p>
        <div className="register-container-login">
          <p className="register-text">Don't have an account? </p>
          <button
            type="button"
            className="register-button"
            onClick={handleRegister}
          >
            Sign-up for free
          </button>
        </div>
      </form>
      <Dialog
        open={showDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            backgroundColor: "#001C30",
            boxShadow: "none",
            borderRadius: "30px",
          },
        }}
      >
        <DialogTitle
          style={{
            color: "#fff",
            fontSize: "24px",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          {dialogTitle}
        </DialogTitle>
        <DialogContent
          style={{ color: "#fff", fontSize: "16px", textAlign: "center" }}
        >
          <p>{dialogMessage}</p>
        </DialogContent>
        <DialogActions
          style={{ padding: "10px", display: "flex", justifyContent: "center" }}
        >
          <Button
            onClick={handleCloseDialog}
            variant="contained"
            autoFocus
            style={{
              padding: "10px 20px",
              borderRadius: "5px",
              backgroundColor: "#1DB954",
              color: "#fff",
              fontSize: "16px",
              fontFamily: "Open Sans",
              cursor: "pointer",
              transition: "background-color 0.3s ease",
            }}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showCodeDialog}
        onClose={handleCloseCodeDialog}
        PaperProps={{
          style: {
            backgroundColor: "#001C30",
            boxShadow: "none",
            borderRadius: "30px",
            width: "400px",
          },
        }}
      >
        <DialogContent
          style={{ color: "#fff", fontSize: "16px", textAlign: "center" }}
        >
          <label htmlFor="code-email">Email</label>
          <input
            type="email"
            id="code-email"
            className="input-field"
            placeholder="Enter your email"
            value={codeEmail}
            onChange={handleCodeEmailChange}
          />
          {isLoading && <p>Loading...</p>} {/* Display loading state */}
        </DialogContent>
        <DialogActions
          style={{ padding: "10px", display: "flex", justifyContent: "center" }}
        >
          <Button
            onClick={handleGetCode}
            variant="contained"
            autoFocus
            style={{
              padding: "10px 20px",
              borderRadius: "5px",
              backgroundColor: "#1DB954",
              color: "#fff",
              fontSize: "16px",
              fontFamily: "Open Sans",
              cursor: "pointer",
              transition: "background-color 0.3s ease",
            }}
          >
            Get Code
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer
        position="top-left"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default Login;
