import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import "./Register.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const Register = () => {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setPasswordMatch(true);
  };

  const handleNavigation = () => {
    navigate("/");
  };
  const handleRePasswordChange = (event) => {
    setRePassword(event.target.value);
    setPasswordMatch(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email)) {
      toast.warning("Invalid email address.", {
        position: "top-left",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        progressStyle: { background: "#1DB954" },
        theme: "colored",
        style: { background: "#001c30", fontFamily: "Open Sans" },
      });
      return;
    }
    if (password.length < 6) {
      toast.warning(
        "Password is too short. Must be at least 6 characters long.",
        {
          position: "top-left",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
          progressStyle: { background: "#1DB954" },
          theme: "colored",
          style: { background: "#001c30", fontFamily: "Open Sans" },
        }
      );

      return;
    }
    if (password !== rePassword) {
      setPasswordMatch(false);
      toast.warning("Passwords do not match.", {
        position: "top-left",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        progressStyle: { background: "#1DB954" },
        theme: "colored",
        style: { background: "#001c30", fontFamily: "Open Sans" },
      });

      return;
    }

    setIsLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: email,
      password: password,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api.jsonplacehold.me/authentication/register",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        let resultJSON = JSON.parse(result);
        if (resultJSON.key !== "SUCCESS") {
          toast.warning(resultJSON.message, {
            position: "top-left",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
            progressStyle: { background: "#1DB954" },
            theme: "colored",
            style: { background: "#001c30", fontFamily: "Open Sans" },
          });
        } else {
          localStorage.setItem("token", resultJSON.body.token);
          localStorage.setItem("email", resultJSON.body.user.email);
          localStorage.setItem("userId", resultJSON.body.user.id);
          setDialogMessage(resultJSON.message);
          setShowDialog(true);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        toast.warning("An error occurred. Please try again.", {
          position: "top-left",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
          progressStyle: { background: "#1DB954" },
          theme: "colored",
          style: { background: "#001c30", fontFamily: "Open Sans" },
        });
        setIsLoading(false);
      });
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
    navigate("/verify");
  };

  return (
    <div className={`register-container`}>
      <form className="register-form" onSubmit={handleSubmit}>
        <h1 className="register-heading">Register</h1>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            className="input-field"
            placeholder="Enter your email"
            style={{ fontFamily: "Open Sans" }}
            value={email}
            onChange={handleEmailChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            className={`input-field ${!passwordMatch ? "mismatch" : ""}`}
            placeholder="Enter your password"
            value={password}
            style={{ fontFamily: "Open Sans" }}
            onChange={handlePasswordChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="re-password">Re-enter Password</label>
          <input
            type="password"
            id="re-password"
            className={`input-field ${!passwordMatch ? "mismatch" : ""}`}
            placeholder="Re-enter your password"
            value={rePassword}
            style={{ fontFamily: "Open Sans" }}
            onChange={handleRePasswordChange}
          />
        </div>

        <button
          type="submit"
          className="register-button"
          disabled={isLoading}
          style={{ fontFamily: "Open Sans" }}
        >
          {isLoading ? "Loading..." : "Register"}
        </button>
        <p className="login-link" onClick={handleNavigation}>
          Already have an account? <span className="login-text">Login</span>
        </p>
      </form>

      <Dialog
        open={showDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            backgroundColor: "#001C30",
            boxShadow: "none",
            borderRadius: "30px",
          },
        }}
      >
        <DialogTitle
          style={{
            color: "#fff",
            fontSize: "24px",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Registration Successful!
        </DialogTitle>
        <DialogContent
          style={{ color: "#fff", fontSize: "16px", textAlign: "center" }}
        >
          <p>{dialogMessage}</p>
        </DialogContent>
        <DialogActions
          style={{ padding: "10px", display: "flex", justifyContent: "center" }}
        >
          <Button
            onClick={handleCloseDialog}
            variant="contained"
            autoFocus
            style={{
              padding: "10px 20px",
              borderRadius: "5px",
              backgroundColor: "#1DB954",
              color: "#fff",
              fontSize: "16px",
              fontFamily: "Open Sans",
              cursor: "pointer",
              transition: "background-color 0.3s ease",
            }}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer
        position="top-left"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default Register;
