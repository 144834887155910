import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import "./Projects.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

const Projects = () => {
  let navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openCreateProjectDialog, setOpenCreateProjectDialog] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchProjects();
  }, []);

  const handleDeleteProject = (project) => {
    setSelectedProject(project);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleProjectNameChange = (event) => {
    setProjectName(event.target.value);
  };

  const handleCloseCreateProjectDialog = () => {
    setProjectName("");
    setOpenCreateProjectDialog(false);
  };

  const handleConfirmDelete = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    var raw = "";

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api.jsonplacehold.me/project/" + selectedProject.projectUUID,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        let resultJSON = JSON.parse(result);
        if (resultJSON.key === "UNAUTHORIZED") {
          localStorage.clear();
          toast.warning("Your session is expired please re-login", {
            position: "top-left",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
            progressStyle: { background: "#1DB954" },
            theme: "colored",
            style: { background: "#001c30", fontFamily: "Open Sans" },
          });
          navigate("/");
        } else if (resultJSON.key !== "SUCCESS") {
          toast.warning(resultJSON.message, {
            position: "top-left",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
            progressStyle: { background: "#1DB954" },
            theme: "colored",
            style: { background: "#001c30", fontFamily: "Open Sans" },
          });
        } else {
          navigate("/dashboard");
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleCreateProject = () => {
    if (projectName === "") {
      toast.warning("Please enter a project name", {
        position: "top-left",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        progressStyle: { background: "#1DB954" },
        theme: "colored",
        style: { background: "#001c30", fontFamily: "Open Sans" },
      });
    } else {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        projectName: projectName,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch("https://api.jsonplacehold.me/project/create", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          let resultJSON = JSON.parse(result);
          if (resultJSON.key === "SUCCESS") {
            handleCloseCreateProjectDialog();
            fetchProjects();
          } else if (resultJSON.key === "UNAUTHORIZED") {
            localStorage.clear();
            toast.warning("Your session is expired please re-login", {
              position: "top-left",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
              progressStyle: { background: "#1DB954" },
              theme: "colored",
              style: { background: "#001c30", fontFamily: "Open Sans" },
            });
            navigate("/");
          } else {
            handleCloseCreateProjectDialog();
            toast(resultJSON.message);
          }
        })
        .catch((error) => console.log("error", error));
    }
  };

  const fetchProjects = () => {
    setIsLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://api.jsonplacehold.me/project/all", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.key === "SUCCESS") {
          const formattedProjects = result.body.map((project) => ({
            ...project,
            formattedTimestamp: new Date(project.timestamp).toLocaleString(),
          }));
          setProjects(formattedProjects);
          setIsLoading(false);
        } else if (result.key === "UNAUTHORIZED") {
          localStorage.clear();
          toast.warning("Your session is expired please re-login", {
            position: "top-left",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
            progressStyle: { background: "#1DB954" },
            theme: "colored",
            style: { background: "#001c30", fontFamily: "Open Sans" },
          });
          navigate("/");
        } else {
          toast.warning(result.message, {
            position: "top-left",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
            progressStyle: { background: "#1DB954" },
            theme: "colored",
            style: { background: "#001c30", fontFamily: "Open Sans" },
          });
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleGoToProject = (project) => {
    navigate("/project/" + project.projectUUID);
  };
  if (!isLoading) {
    return (
      <div>
        <Navbar activePage="dashboard" />
        <div className="projects-container">
          <h1>Projects</h1>
          <div className="create-project-button-container">
            <button
              onClick={() => setOpenCreateProjectDialog(true)}
              className="create-project-button"
            >
              Create Project
            </button>
          </div>
          <table className="projects-table">
            <thead>
              <tr>
                <th>Project UUID</th>
                <th>Project Name</th>
                <th>Created Time</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {projects.map((project) => (
                <tr key={project.projectUUID}>
                  <td>{project.projectUUID}</td>
                  <td>{project.name}</td>
                  <td>{project.formattedTimestamp} UTC</td>
                  <td>
                    <Button onClick={() => handleGoToProject(project)}>
                      Go
                    </Button>
                    <Button>Edit</Button>
                    <IconButton
                      color="error"
                      aria-label="Delete"
                      onClick={() => handleDeleteProject(project)}
                      className="delete-icon-button"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          PaperProps={{
            style: {
              backgroundColor: "#001C30",
              boxShadow: "none",
              borderRadius: "30px",
              color: "#fff",
            },
          }}
        >
          <DialogTitle>Delete Project</DialogTitle>
          <DialogContent>
            Are you sure you want to delete the project?
          </DialogContent>
          <DialogActions>
            <button onClick={handleCloseDialog} className="dialogButton">
              Cancel
            </button>
            <button onClick={handleConfirmDelete} className="dialogButton">
              Delete
            </button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openCreateProjectDialog}
          onClose={handleCloseCreateProjectDialog}
          PaperProps={{
            style: {
              backgroundColor: "#001C30",
              boxShadow: "none",
              borderRadius: "30px",
              width: "400px",
            },
          }}
        >
          <DialogContent
            style={{ color: "#fff", fontSize: "16px", textAlign: "center" }}
          >
            <label>Project Name</label>
            <input
              id="project-name"
              className="input-field"
              placeholder="Enter a project name"
              value={projectName}
              onChange={handleProjectNameChange}
            />
          </DialogContent>
          <DialogActions
            style={{
              padding: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={handleCreateProject}
              variant="contained"
              autoFocus
              style={{
                padding: "10px 20px",
                borderRadius: "5px",
                backgroundColor: "#1DB954",
                color: "#fff",
                fontSize: "16px",
                fontFamily: "Open Sans",
                cursor: "pointer",
                transition: "background-color 0.3s ease",
              }}
            >
              Create Project
            </Button>
          </DialogActions>
        </Dialog>
        <ToastContainer
          position="top-left"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </div>
    );
  } else {
    return (
      <div>
        <Navbar activePage="dashboard" />
        <div className="projects-container">
          <h1>Projects</h1>
          <div className="create-project-button-container">
            <button
              onClick={() => setOpenCreateProjectDialog(true)}
              className="create-project-button"
            >
              Create Project
            </button>
          </div>
          <table className="projects-table">
            <thead>
              <tr>
                <th>Project UUID</th>
                <th>Project Name</th>
                <th>Created Time</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {projects.map((project) => (
                <tr key={project.projectUUID}>
                  <td>{project.projectUUID}</td>
                  <td>{project.name}</td>
                  <td>{project.formattedTimestamp} UTC</td>
                  <td>
                    <Button onClick={() => handleGoToProject(project)}>
                      Go
                    </Button>
                    <Button>Edit</Button>
                    <IconButton
                      color="error"
                      aria-label="Delete"
                      onClick={() => handleDeleteProject(project)}
                      className="delete-icon-button"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "50px",
          }}
        >
          <TailSpin
            height="80"
            width="80"
            color="#1DB954"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperClass=""
            visible={true}
          />
        </div>
        <ToastContainer
          position="top-left"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </div>
    );
  }
};

export default Projects;
