import React, { useState } from "react";
import Navbar from "./Navbar";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import "./About.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TailSpin } from "react-loader-spinner";

const About = () => {
  return (
    <div className="about-container">
      <Navbar activePage="about" />
      <p>
        A modern JSON Placeholder service for your apps. Just create a project,
        add some endpoints, and start using it!
      </p>
    </div>
  );
};

export default About;
