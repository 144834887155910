import {
  BrowserRouter as Router,
  Route,
  NavLink,
  Routes,
} from "react-router-dom";

import "./App.css";
import Login from "./Login";
import Register from "./Register";
import Verification from "./Verification";
import ForgottenPassword from "./ForgottenPassword";
import Projects from "./Projects";
import Project from "./Project";
import Account from "./Account";
import About from "./About";
function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/sign-up" element={<Register />} />
        <Route exact path="/verify" element={<Verification />} />
        <Route
          exact
          path="/forgottenPassword"
          element={<ForgottenPassword />}
        />
        <Route exact path="/dashboard" element={<Projects />} />
        <Route exact path="/project/:projectId" element={<Project />} />
        <Route exact path="/account" element={<Account />} />
        <Route exact path="/about" element={<About />} />
      </Routes>
    </Router>
  );
}

export default App;
