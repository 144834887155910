import React, { useState } from "react";
import Navbar from "./Navbar";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import "./Account.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

const Account = () => {
  let navigate = useNavigate();
  const [openChangePasswordDialog, setOpenChangePasswordDialog] =
    useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const handleOpenChangePasswordDialog = () => {
    setOpenChangePasswordDialog(true);
  };

  const handleCloseChangePasswordDialog = () => {
    setOpenChangePasswordDialog(false);
  };

  const handleChangePassword = () => {
    if (newPassword !== confirmNewPassword) {
      console.log(newPassword, confirmNewPassword);
      toast.warning("Passwords do not match.", {
        position: "top-left",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        progressStyle: { background: "#1DB954" },
        theme: "colored",
        style: { background: "#001c30", fontFamily: "Open Sans" },
      });
    } else {
      setIsLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      var raw = JSON.stringify({
        oldPassword: oldPassword,
        newPassword: newPassword,
      });

      var requestOptions = {
        method: "PATCH",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        "https://api.jsonplacehold.me/authentication/changePassword",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          let resultJSON = JSON.parse(result);
          if (resultJSON.key === "UNAUTHORIZED") {
            toast.warning("Your session is expired please re-login", {
              position: "top-left",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
              progressStyle: { background: "#1DB954" },
              theme: "colored",
              style: { background: "#001c30", fontFamily: "Open Sans" },
            });
          } else if (resultJSON.key !== "SUCCESS") {
            toast.warning(resultJSON.message, {
              position: "top-left",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
              progressStyle: { background: "#1DB954" },
              theme: "colored",
              style: { background: "#001c30", fontFamily: "Open Sans" },
            });
          } else {
            setIsLoading(false);
            localStorage.clear();
            navigate("/");
            handleCloseChangePasswordDialog();
          }
        })
        .catch((error) => console.log("error", error));
    }
  };
  if (isLoading) {
    return (
      <div className="account-container">
        <Navbar activePage="account" />
        <div className="centered">
          <Button
            className="change-password-button"
            onClick={handleOpenChangePasswordDialog}
          >
            Change Password
          </Button>
        </div>
        <Dialog
          open={openChangePasswordDialog}
          onClose={handleCloseChangePasswordDialog}
          PaperProps={{
            style: {
              backgroundColor: "#001C30",
              boxShadow: "none",
              borderRadius: "30px",
              width: "400px",
            },
          }}
        >
          <DialogTitle style={{ color: "#fff", textAlign: "center" }}>
            Change Password
          </DialogTitle>
          <DialogContent style={{ color: "#fff" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "50px",
              }}
            >
              <TailSpin
                height="80"
                width="80"
                color="#1DB954"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperClass=""
                visible={true}
              />
            </div>
            <label htmlFor="old-password">Old Password</label>
            <input
              type="password"
              id="old-password"
              className="input-field"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
            <label htmlFor="new-password">New Password</label>
            <input
              type="password"
              id="new-password"
              className="input-field"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <label htmlFor="confirm-new-password">Re-enter New Password</label>
            <input
              type="password"
              id="confirm-new-password"
              className="input-field"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
          </DialogContent>
          <DialogActions
            style={{
              padding: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={handleChangePassword}
              variant="contained"
              autoFocus
              style={changePasswordButtonStyle}
            >
              Change Password
            </Button>
          </DialogActions>
        </Dialog>
        <ToastContainer
          position="top-left"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </div>
    );
  } else {
    return (
      <div className="account-container">
        <Navbar activePage="account" />
        <div className="centered">
          <Button
            className="change-password-button"
            onClick={handleOpenChangePasswordDialog}
          >
            Change Password
          </Button>
        </div>
        <Dialog
          open={openChangePasswordDialog}
          onClose={handleCloseChangePasswordDialog}
          PaperProps={{
            style: {
              backgroundColor: "#001C30",
              boxShadow: "none",
              borderRadius: "30px",
              width: "400px",
            },
          }}
        >
          <DialogTitle style={{ color: "#fff", textAlign: "center" }}>
            Change Password
          </DialogTitle>
          <DialogContent style={{ color: "#fff" }}>
            <label htmlFor="old-password">Old Password</label>
            <input
              type="password"
              id="old-password"
              className="input-field"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
            <label htmlFor="new-password">New Password</label>
            <input
              type="password"
              id="new-password"
              className="input-field"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <label htmlFor="confirm-new-password">Re-enter New Password</label>
            <input
              type="password"
              id="confirm-new-password"
              className="input-field"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
          </DialogContent>
          <DialogActions
            style={{
              padding: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={handleChangePassword}
              variant="contained"
              autoFocus
              style={changePasswordButtonStyle}
              disabled={isLoading} // Disable the button when isLoading is true
            >
              Change Password
            </Button>
          </DialogActions>
        </Dialog>
        <ToastContainer
          position="top-left"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </div>
    );
  }
};

const changePasswordButtonStyle = {
  padding: "10px 20px",
  borderRadius: "5px",
  backgroundColor: "#1DB954",
  color: "#fff",
  fontSize: "16px",
  fontFamily: "Open Sans",
  cursor: "pointer",
  transition: "background-color 0.3s ease",
};

export default Account;
